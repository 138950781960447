import React from "react"
import ForgotPassword from "../components/login/forgot-password.component"
import { graphql } from "gatsby"
import AuthLayout from "../components/login/layout"
import { useI18next, I18nextContext } from "gatsby-plugin-react-i18next"
import Seo from "../components/seo"
const Forgotpassword = ({ pageContext: { i18n } }) => {
  const { t } = useI18next()
  const context = React.useContext(I18nextContext)
  const lng = context.language
  return (
    <AuthLayout>
      <Seo
        seoIdentifier="/forgotpassword"
        originalPath={i18n.originalPath}
        lang={lng}
        title={t("forgot_password.seo.title")}
        description={t("forgot_password.seo.description")}
      />
      <ForgotPassword t={t} lng={lng} />
    </AuthLayout>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default Forgotpassword
