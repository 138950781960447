import React, { Component } from "react"
import styled from "styled-components"
import * as utils from "../GlobalStyles/utils"
import FormButton from "../form-button/form-button.component"
import Messages from "../Message/message.data"
import Message from "../Message/message.component"
import { BodyText } from "../texts"
import { Title } from "../GlobalStyles/utils"
import { Link } from "gatsby"
import { Column } from "../styled"
import { UpdatedButton } from "@youngagency/young-ui"

const Validator = styled.label`
  ${utils.inputValidator};
`

const Text = styled.label`
  padding-right: 20px;
  color: ${utils.tertiaryColor};
  ${utils.loginText};
`

const FormButtonStyled = styled.button`
  ${utils.formButton};
  border-radius: 30px;
  margin-top: 0px;
  cursor: pointer;
`
class ForgotPassword extends Component {
  constructor(props) {
    super(props)
    this.state = {
      email: "",
      emailValid: "",
      data: [],
    }
    this.body = styled.div`
      height: auto;
      min-height: 100vh;
      width: 100%;
      width: -webkit-fill-available;
      width: -moz-available;
      ${utils.marginH("50px")};
      ${utils.flexCenter};
      ${utils.media
        .tablet`margin-left:5%;margin-right:5%;min-height:calc(100vh - 60px)`};
    `
    this.Content = styled.div`
      ${utils.flexCenter};
      ${utils.flexColumn};
      width: 812px;
      max-width: 100%;
      ${utils.media.desktop`width: 400px;padding-bottom:30px;`};
    `
    this.InputLine = styled.div`
      ${utils.inputLine};
      justify-content: center;
    `
    this.InputContainer = styled.div`
      ${utils.inputContainer};
      width: 100%;
      align-items: center;
      margin-top: 60px;
      max-width: 400px;
    `
    this.Input = styled.input`
      ${utils.input};
    `
  }
  addMessage = msg => {
    if (!this.state.data.includes(msg)) {
      var newArray = this.state.data.slice()
      newArray.push(msg)
      this.setState(() => ({
        data: newArray,
      }))
      setTimeout(
        function () {
          this.setState(prevState => ({
            data: prevState.data.filter(el => el != msg),
          }))
        }.bind(this),
        3000
      )
    }
  }
  handleKeyPress = e => {
    if (e.key === "Enter" && this.state.emailValid) {
      this.sendRequest()
    }
  }
  handleInputChange = event => {
    const name = event.target.name
    const value = event.target.value

    // setState receives a callback for when the state has finished updating
    this.checkValid(name, value)
  }
  checkValid = (name, value) => {
    let emailValid = this.state.emailValid

    switch (name) {
      case "email":
        emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) != null
        break
      default:
        break
    }

    this.setState({
      [name]: value,
      emailValid: emailValid,
    })
  }
  sendRequest = () => {
    let data = {
      email: this.state.email,
    }
    var formBody = []
    for (var property in data) {
      var encodedKey = encodeURIComponent(property)
      var encodedValue = encodeURIComponent(data[property])
      formBody.push(encodedKey + "=" + encodedValue)
    }
    formBody = formBody.join("&")
    let lng = this.props.lng
    fetch(process.env.GATSBY_IDENTITY_URL + "/api/account/forgotpassword", {
      method: "POST",
      body: formBody,
      headers: {
        Accept: "application/x-www-form-urlencoded",
        "Content-Type": "application/x-www-form-urlencoded",
        "Accept-Language": lng,
      },
    })
      .then(response => {
        if (response.status === 200) {
          this.addMessage(Messages.formSuccess)
          return 0
        }
        return response.json()
      })
      .then(result => {
        if (
          result["error_description"] !== null &&
          result["error_description"] !== undefined
        ) {
          this.addMessage(Messages.emailNotExist)
        }
      })
      .catch(error => {
        this.addMessage(Messages.genericError)
        console.log(error)
      })
  }

  render() {
    const { t } = this.props
    return (
      <div style={{ position: "relative" }}>
        <Message data={this.state.data} />
        <this.body>
          <this.Content
            style={{
              maxWidth: "400px",
              width: "100%",
            }}
          >
            <Title color="#1a1a1a" style={{ textAlign: "center" }}>
              {t("forgot_password.title")}
            </Title>

            <BodyText
              euclid
              color={"#1a1a1a"}
              style={{
                textAlign: "center",
              }}
            >
              {t("forgot_password.text")}
            </BodyText>
            <Column gap="xs" width="100%">
              <this.InputLine>
                <this.InputContainer>
                  <div
                    style={{
                      paddingLeft: "6px",
                      display: "flex",
                      height: "12px",
                      width: "calc(100%)",
                      justifyContent: "space-between",
                    }}
                  >
                    <Text bold size="12px">
                      {"E-mail"}
                    </Text>
                  </div>
                  <this.Input
                    type="text"
                    name="email"
                    className="form-control-contact"
                    value={this.state.email}
                    onChange={this.handleInputChange}
                    required=""
                    onKeyPress={this.handleKeyPress}
                    style={{ borderRadius: "30px", width: "calc(100%)" }}
                  />
                  <Validator
                    className={
                      !this.state.emailValid && this.state.email !== ""
                        ? "active"
                        : ""
                    }
                  >
                    {t("form.invalidEmail")}
                  </Validator>
                </this.InputContainer>
              </this.InputLine>
              {/* <FormButton
                text={t("buttons.reset_password")}
                callback={this.sendRequest}
                style={FormButtonStyled}
                active={this.state.emailValid}
              /> */}
              <UpdatedButton
                variant="primary"
                colorScheme="pink"
                size="sm"
                onClick={this.sendRequest}
                disabled={!this.state.emailValid}
                style={{
                  borderRadius: "100px",
                  width: "100%",
                }}
              >
                {t("buttons.reset_password")}
              </UpdatedButton>
            </Column>
          </this.Content>
        </this.body>
      </div>
    )
  }
}

export default ForgotPassword
